
import { defineComponent } from 'vue';
import _ from 'lodash';

export default defineComponent({
  props: {
    value: {
      default: () => {
        return [
          { color: '#f7ba2a', value: 0, text: '5' },
          { color: '#f7ba2a', value: 0, text: '4' },
          { color: '#f7ba2a', value: 0, text: '3' },
          { color: '#f7ba2a', value: 0, text: '2' },
          { color: '#f7ba2a', value: 0, text: '1' },
        ];
      },
    },
    total: { default: 0 },
    summaryAverage: { default: 0 },
    loading: { default: false },
  },
  computed: {
    summary() {
      let summary = this.value as any;
      let keys = Object.keys(summary);

      keys.sort();

      let newArr = [] as any;

      for (var i = keys.length - 1; i >= 0; i--) {
        var k = keys[i];
        newArr.push(summary[k] as any);
      }

      let total = newArr.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue.value;
      }, 0);

      return newArr.map(item => {
        item.average = (item.value / total) * 100
        return item;
      });
    },
    totalReviews() {
      let total = this.summary.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue.value;
      }, 0);

      return total;
    },
    formattedAverage() {
      return parseFloat(this.summaryAverage as any).toFixed(1);
    },
  },
});
