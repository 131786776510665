import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-12"
}
const _hoisted_3 = {
  key: 1,
  class: "col-lg-9"
}
const _hoisted_4 = { class: "col-1 text-right d-flex justify-content-start" }
const _hoisted_5 = { class: "font-weight-bold" }
const _hoisted_6 = { class: "col-8" }
const _hoisted_7 = ["aria-valuenow"]
const _hoisted_8 = { class: "col-3 text-right d-flex justify-content-end" }
const _hoisted_9 = { class: "font-weight-bold" }
const _hoisted_10 = {
  key: 2,
  class: "col-lg-3 flex-grow-1"
}
const _hoisted_11 = { class: "w-100 d-flex flex-column align-items-center justify-content-center rating" }
const _hoisted_12 = {
  class: "text-center mt-4",
  style: {"color":"#990abd","font-weight":"bold"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_rate = _resolveComponent("el-rate")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_skeleton, {
            animated: "",
            row: "5"
          })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary, (item, key) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              key: key
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(item.text), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "progress mb-5",
                  role: "progressbar",
                  "aria-label": "Success example",
                  "aria-valuenow": item.average,
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }, [
                  _createElementVNode("div", {
                    class: "progress-bar",
                    style: _normalizeStyle(`width: ${item.average}%;background:#f7ba2a`)
                  }, null, 4)
                ], 8, _hoisted_7)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(item.value) + " reviews", 1)
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.formattedAverage), 1),
            _createVNode(_component_el_form_item, { class: "mb-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_rate, {
                  disabled: "",
                  size: "small",
                  class: "rating-size",
                  "model-value": parseFloat(_ctx.summaryAverage),
                  "allow-half": true
                }, null, 8, ["model-value"])
              ]),
              _: 1
            }),
            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.totalReviews) + " reviews ", 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}