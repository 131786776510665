
import { defineComponent } from 'vue';
import SearchHeader from '@/views/new-design/layout/SearchHeader.vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { Actions, Getters } from '@/store/enums/TenantEnums';
import { Actions as StoreActions } from '@/store/enums/StoreEnums';
import { Actions as BusinessActions } from '@/store/enums/BusinessEnums';
import moment from 'moment';
import toasts from '@/utils/toasts';
import store from '@/store';
import DataTable from '@/components/datatables/KTDataTable.vue';
import {
  Getters as TenantReviewGetters,
  Actions as TenantReviewActions,
  Mutations as TenantReviewMutations,
} from '@/store/enums/TenantReviewEnums';
import EmailReportModal from '@/views/new-design/pages/ViewTenantReview/EmailReport.vue';
import PDFGenerator from '@/views/new-design/pages/ViewTenantReview/PDFGenerator.vue';
import LedgerReport from '@/views/new-design/pages/ViewTenantReview/Report/Ledger.vue';
import timeUtil from '@/utils/time';
import TenantReviewSummary from '@/views/new-design/pages/Dashboard/TenantReviewSummary.vue';
import EmptyList from '@/components/EmptyList.vue';
import _ from 'lodash';
import {
  Getters as NotificationGetters,
  Actions as NotificationActions,
} from '@/store/enums/NotificationEnums';
import { Actions as AssetActions } from '@/store/enums/AssetEnums';
import useBreakpoints from 'vue-next-breakpoints';

export default defineComponent({
  components: {
    SearchHeader,
    EmailReportModal,
    PDFGenerator,
    LedgerReport,
    DataTable,
    TenantReviewSummary,
    EmptyList,
  },
  data: () => {
    return {
      loading: false,
      downloadingOpenHouse: false,
      table: {
        items: [],
        currentPage: 1,
        totalPages: 0,
        perPage: 15,
        total: 0,
      },
      pending: {
        headers: [
          { name: 'Tenant Name', key: 'name' },
          {
            name: 'Submitted By',
            key: 'agent',
            align: 'center',
            sortable: false,
          },
        ],
        items: [],
        currentPage: 1,
        totalPages: 0,
        perPage: 15,
        total: 0,
      },
      sendReport: false,
      sendingReport: false,
      selectedReference: null as any,
      pdfFile: '',
      reviewSummary: [
        { color: '#990bbd', value: 27, text: '5' },
        { color: '#990bbd', value: 15, text: '4' },
        { color: '#990bbd', value: 7, text: '3' },
        { color: '#990bbd', value: 3, text: '2' },
        { color: '#990bbd', value: 1, text: '1' },
      ],
      selectedDates: timeUtil.dateRange(365) as any,
      debounceFilterTenantReviewSummary: Function() as any,
      loadingSummary: false,
      submittedTenantHistoryLoading: false,
      loadingPendingTenantReviews: false,
      marketingIndicator: 'Downloading',
    };
  },
  async mounted() {
    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);

    (this.$refs.searchHeader as any).clear();

    await this.fetchCurrentSettings();

    this.getRecentAddedReferences();

    this.getPendingReferences();

    this.retrieveTenantReviewSummary();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      searhTenantErrors: Getters.GET_SEARCH_TENANT_ACTION_ERROR,
      allTenants: Getters.GET_ALL_TENANTS,
      tenants: Getters.GET_SEARCH_TENANT_LIST,
      recentReferences: TenantReviewGetters.GET_RECENT_SUBMITTED_REFERENCES,
      actionError: TenantReviewGetters.GET_TENANT_REVIEW_ACTION_ERROR,
      tenantReference: TenantReviewGetters.GET_TENANT_REVIEW,
      tenantReviewsFilter: TenantReviewGetters.GET_REVIEWS_FILTER_TO_AGENCY,
      pendingTenantReviews: TenantReviewGetters.GET_PENDING_TENANT_REVIEWS,
      pendingReviewsFilter:
        TenantReviewGetters.GET_PENDING_REVIEWS_FILTER_TO_AGENCY,
      getTenantReviewSummary: TenantReviewGetters.GET_TENANT_REVIEW_SUMMARY,
      settings: NotificationGetters.GET_CURRENT_SETTINGS,
    }),
    isDueAuthorizationAccessible() {
      return this.settings.due_authorization === this.authUser.id;
    },
    parseTenantReviewSummary() {
      if (this.getTenantReviewSummary?.review_graph) {
        let summary = JSON.parse(
          this.getTenantReviewSummary?.review_graph
        ) as any;
        return summary;
      } else {
        return [];
      }
    },
    doesntHavePlan() {
      return (
        typeof this.authUser.subscription === 'undefined' ||
        this.authUser.subscription.length === 0
      );
    },
    averageRating() {
      return 0 as any;
    },
    dateRange() {
      if (this.selectedDates?.length) {
        return [
          timeUtil.dateFormat(this.selectedDates[0], 'default'),
          timeUtil.dateFormat(this.selectedDates[1], 'default'),
        ];
      }

      return [
        timeUtil.dateFormat(moment().subtract(1, 'years').format(), 'default'),
        timeUtil.dateFormat(moment().format(), 'default'),
      ];
    },
    shortcuts() {
      return [
        {
          text: 'Last 30 days',
          value: () => {
            return timeUtil.dateRange(30);
          },
        },
        {
          text: 'Last 3 months',
          value: () => {
            return timeUtil.dateRange(90);
          },
        },
        {
          text: 'Last 6 months',
          value: timeUtil.dateRange(180),
        },
        {
          text: 'Last 12 months',
          value: timeUtil.dateRange(365),
        },
      ];
    },
    formattedRecentSubmittedTenant() {
      return this.recentReferences.tenant_reviews.map((item) => {
        item.name = `${item.tenant.first_name} ${item.tenant.last_name}`;
        item.agent = `${item.user.first_name} ${item.user.last_name}`;
        item.date = timeUtil.dateFormat(item.date_created);

        return item;
      });
    },
    formattedPendingReviews() {
      return this.pendingTenantReviews.tenant_reviews.map((item) => {
        item.name = `${item.tenant.first_name} ${item.tenant.last_name}`;
        item.agent = `${item.user.first_name} ${item.user.last_name}`;

        return item;
      });
    },
    breakpoint(){
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    submittedReviewHeader(){
      return [
        { name: 'Tenant Name', key: 'name' },
        { name: 'Submitted By', key: 'agent', hidden: this.isMobile },
        { name: 'Submitted Date', key: 'date', hidden: this.isMobile },
        { name: 'Action', key: 'action', align: 'center', sortable: false },
      ]
    }
  },
  watch: {
    dateRange: {
      handler(value) {
        const beforeAYear = moment(this.dateRange[1]).subtract(1, 'years');
        if (
          moment(this.dateRange[0]) <
          moment(this.dateRange[1]).subtract(1, 'years')
        ) {
          toasts.error(
            {},
            `You are not allowed to select a date before ${beforeAYear.format(
              'DD/MM/YYYY'
            )}`
          );
          return false;
        }

        this.debounceFilterTenantReviewSummary();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      searchTenantRef: Actions.SEARCH_TENANT,
      fetchRecentAddedReferences:
        TenantReviewActions.FETCH_RECENT_SUBMITTED_REFERENCES,
      sendToEmails: TenantReviewActions.SEND_TENANT_REVIEW_TO_EMAILS,
      fetchReference: TenantReviewActions.FETCH_TENANT_REVIEW_BY_REFERENCE_NO,
      fetchPendingReference: TenantReviewActions.FETCH_PENDING_TENANT_REVIEW,
      toogleReviewFilter: TenantReviewActions.TOOGLE_REVIEW_AGENCY_FILTER,
      tooglePendingReviewFilter:
        TenantReviewActions.TOOGLE_PENDING_REVIEW_AGENCY_FILTER,
      fetchTenantReviewSummary: TenantReviewActions.FETCH_TENANT_REVIEW_SUMMARY,
      fetchCurrentSettings: NotificationActions.FETCH_CURRENT_SETTINGS,
      generateFileUrl: AssetActions.GENERATE_FILE_URL,
    }),
    disabledDates(date) {
      return moment(date) > moment();
    },
    submitReport() {
      // show page loading
      store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');

      this.$router.push({ name: 'submit-tenant-review' });
    },
    requestTenant() {
      // show page loading
      store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');

      this.$router.push({ name: 'request-tenant-report' });
    },
    async handleSubmitSearchTenant(payload) {
      this.loading = true;
      const params = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        date_of_birth: payload.date_of_birth
          ? moment(payload.date_of_birth).format('YYYY-MM-DD')
          : null,
      };

      await this.searchTenantRef(params)
        .then(() => {
          if (!this.tenants.length && !this.doesntHavePlan) {
            toasts.alert(`No tenant information found`);
            return;
          }

          this.$router.push({
            name: 'search-tenant-report',
            query: {
              ref: 'dashboard',
            },
          });
        })
        .catch(() => {
          const { errors, message } = this.searhTenantErrors;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async handleSwitchChange(event) {
      const isChecked = event.target.checked;

      await this.toogleReviewFilter(isChecked);

      return await this.getRecentAddedReferences();
    },
    async handlePendingSwitchChange(event) {
      const isChecked = event.target.checked;

      await this.tooglePendingReviewFilter(isChecked);

      return await this.getPendingReferences();
    },
    handleReviewSummarySwitchChange(event) {
      console.log(1);
    },
    async getRecentAddedReferences() {
      const params = this.tenantReviewsFilter ? { filter: 'agency' } : {};
      this.submittedTenantHistoryLoading = true;

      await this.fetchRecentAddedReferences(params)
        .then(() => {
          this.table.items = this.formattedRecentSubmittedTenant;
          this.table.currentPage =
            this.recentReferences.pagination.current_page;
          this.table.totalPages = this.recentReferences.pagination.total_pages;
          this.table.perPage = this.recentReferences.pagination.per_page;
          this.table.total = this.recentReferences.pagination.total;
        })
        .finally(() => {
          this.submittedTenantHistoryLoading = false;
        });
    },
    async getPendingReferences() {
      const params = { filter: 'agency' };
      this.loadingPendingTenantReviews = true;

      await this.fetchPendingReference(params)
        .then(() => {
          this.pending.items = this.formattedPendingReviews;
          this.pending.currentPage =
            this.pendingTenantReviews.pagination.current_page;
          this.pending.totalPages =
            this.pendingTenantReviews.pagination.total_pages;
          this.pending.perPage = this.pendingTenantReviews.pagination.per_page;
          this.pending.total = this.pendingTenantReviews.pagination.total;
        })
        .finally(() => (this.loadingPendingTenantReviews = false));
    },
    viewTenant(tenant) {
      // show page loading
      store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');

      this.$router.push({
        name: 'view-tenant-review',
        params: { refNo: tenant.reference_no },
      });
    },
    async emailReport(emails) {
      const payload = {
        refNo: this.tenantReference?.reference_no,
        emails: Object.values(emails),
        //report: this.pdfFile,
      };

      this.sendingReport = true;
      await this.sendToEmails(payload)
        .then(() => {
          toasts.success('Tenant Review Successfully Sent');
          (this.$refs.emailReport as any).clear();
        })
        .catch(() => {
          const { errors, message } = this.actionError;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.sendingReport = false;
          this.sendReport = false;
        });
    },
    // async handleBeforeDownload(pdf) {
    //   if (this.pdfFile !== pdf) {
    //     this.pdfFile = pdf;
    //   }
    // },
    async setToEmail(reference) {
      this.sendReport = true;
      await this.fetchReference(reference?.reference_no);

      //(this.$refs.sendPdfReport as any).generatePDf();
    },
    async retrieveTenantReviewSummary() {
      this.loadingSummary = true;
      await this.fetchTenantReviewSummary({
        from: this.dateRange[0],
        to: this.dateRange[1],
      });
      this.loadingSummary = false;
    },
    async handleDownloadOpenHouse() {
      if (this.downloadingOpenHouse) return;
      this.downloadingOpenHouse = true;

      if (
        typeof this.authUser.custom_username === 'undefined' ||
        this.authUser.custom_username === null ||
        this.authUser.custom_username === ''
      ) {
        this.downloadingOpenHouse = false;
        return toasts.error(
          '',
          'Please setup custom link on Business Details to have your marketing PDF generated.'
        );
      }

      const fileURL = this.authUser?.marketing_pdf_src;
      const filename = this.authUser?.custom_username + '.pdf';

      this.marketingIndicator = 'Generating';
      let generateTimeout = 10;

      await this.generateFileUrl(fileURL).then(async (data) => {
        generateTimeout = await data.timeout;
      });

      console.log('Downloadable generateTimeout', generateTimeout);
      console.log('Downloadable file URL', fileURL);

      const generateInterval = setInterval(async () => {
        console.log('Generate Timeout', generateTimeout);
        if (generateTimeout <= 5) {
          this.marketingIndicator = 'Downloading';
        }

        if (generateTimeout == 0) {
          this.createDownloadLink(filename, fileURL);

          this.downloadingOpenHouse = false;
          clearInterval(generateInterval);
        } else {
          generateTimeout--;
        }
      }, 1000);
    },

    createDownloadLink(filename, fileURL) {
      const fileLink = document.createElement('a');
      fileLink.download = `${filename}.pdf`;
      fileLink.href = `${fileURL}?download=true`;
      fileLink.target = '_blank';
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    },
  },

  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
  },
  created() {
    this.debounceFilterTenantReviewSummary = _.debounce(() => {
      this.retrieveTenantReviewSummary();
    }, 1000);
  },
});
